import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useStatClientPermissions = function (user) {
    var hasPermissionToDeleteStatClient = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT, MethodCode.DELETE);
    var hasPermissionToEditStatClient = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT, MethodCode.EDIT);
    var hasPermissionToViewStatClient = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT, MethodCode.VIEW);
    var hasPermissionToCreateStatClient = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.STAT_CLIENT, MethodCode.CREATE);
    return {
        hasPermissionToDeleteStatClient: hasPermissionToDeleteStatClient,
        hasPermissionToEditStatClient: hasPermissionToEditStatClient,
        hasPermissionToViewStatClient: hasPermissionToViewStatClient,
        hasPermissionToCreateStatClient: hasPermissionToCreateStatClient,
    };
};
export default useStatClientPermissions;
